import React from 'react';
import styled from 'styled-components';
import { VehicleImage } from './VehicleImage';
import { Text } from 'vcc-ui';
import { HorizontalLayout, Translation } from '@vcc-gcs/bookingengine-shared-frontend';

const StyledVehicleImage = getStyledVehicleImage();

export const VehicleInformationView = ({ vehicle = {}, onHandleViewVehicleDetails }) => {
  const { assets, formattedLicensePlate, VIN, details, type, odometerReading } = vehicle;
  const { model, modelYear, engine, transmission } = details || {};

  return (
    <>
      <Text className={'title-headings'}>
        <Translation textId="bookings.booking.vehicleDetails.title" />
      </Text>
      <StyledVehicleImage className="vehicle-image" url={(assets && assets?.url) || ''} />
      {formattedLicensePlate && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.registration" />
          </Text>
          <Text className={'content-text'} extend={{ fontWeight: 'bold' }}>
            {formattedLicensePlate}
          </Text>
        </HorizontalLayout>
      )}
      {VIN && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.vin" />
          </Text>
          <a className={'content-text'} href={'/#'} onClick={(e) => onHandleViewVehicleDetails(e)}>
            <Text>{VIN}</Text>
          </a>
        </HorizontalLayout>
      )}
      {model && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.model" />
          </Text>
          <Text className={'content-text'}>{`${model} ${modelYear}`}</Text>
        </HorizontalLayout>
      )}
      {engine && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.engine" />
          </Text>
          <Text className={'content-text'}>{`${engine} `}</Text>
        </HorizontalLayout>
      )}
      {transmission && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.transmission" />
          </Text>
          <Text className={'content-text'}>{`${transmission} `}</Text>
        </HorizontalLayout>
      )}
      {type && (
        <HorizontalLayout className={'content'}>
          <Text className={'content-headings'}>
            <Translation textId="bookings.booking.vehicleDetails.type" />
          </Text>
          <Text className={'content-text'}>{type}</Text>
        </HorizontalLayout>
      )}
      <HorizontalLayout className={'content'}>
        <Text className={'content-headings'}>
          <Translation textId="bookings.booking.vehicleDetails.mileage" />
        </Text>
        <Text className={'content-text'}>
          {odometerReading.value} {odometerReading.unit}
        </Text>
      </HorizontalLayout>
    </>
  );
};

function getStyledVehicleImage() {
  return styled(VehicleImage)`
    align-items: flex-end;
    padding-bottom: ${(props) => props.url === '' && '16px'};
  `;
}
