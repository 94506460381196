import gql from 'graphql-tag';

const sharedBookingFragment = gql`
  fragment SharedBookingFragment on Booking {
    id
    bookingTimestamp
    retailerId
    caseReference
    campaignCode
    bookingReference
    bookingLanguage
    customer {
      title
      firstName
      lastName
      email
      phone
      streetAddress
      city
      postCode
    }
    timeslots {
      start
      end
    }
    appointmentDetails {
      price
      servicePrice
      customerInformation
      dropoffOptionTranslated
      dropoffOption
      pickupAddress
      dropoffOptionObj {
        uid
        id
        heading
        details
      }
      subDropoffOptionsObj {
        uid
        id
        heading
        details
      }
      primaryServices {
        uid
        id
        price
        priceInfo
        heading
        subcaption
        longDescription
      }
      additionalOptions {
        uid
        price
        priceInfo
        description
        dealerDescription
      }
      servicePlan
      servicePlanAdditionalInfo
    }
    vehicle {
      brand
      formattedLicensePlate
      licensePlate
      odometerReading {
        value
        unit
      }
      VIN
      type
      details {
        model
        modelCode
        modelYear
        engine
        engineCode
        fuelType
        transmission
        transmissionCode
        variantId
      }
    }
    dealer {
      id
    }
    events {
      eventId
      type
      state
      created
      handledBy
      handled
      origin
    }
    origin {
      source
      userName
      organization
    }
    marketAttributes {
      currencySymbol
      market
    }
    worklistId
  }
`;

export const GET_BOOKING = gql`
  query booking($bookingId: String!) {
    booking(bookingId: $bookingId) {
      ...SharedBookingFragment
      vehicleObject {
        assets {
          id
          url
        }
      }
    }
  }
  ${sharedBookingFragment}
`;

export const GET_MARKET_DATA = gql`
  query market($marketId: String!, $language: String) {
    market(marketId: $marketId) {
      currencySymbol
      servicePlans(language: $language, includeDisabled: true) {
        id
        label
      }
    }
  }
`;
